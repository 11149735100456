import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

export interface ITabIdGeneratorMiddlewareProps { }

const TabIdGeneratorMiddlewarePage: React.FunctionComponent<ITabIdGeneratorMiddlewareProps> = () => {
    let { pathname } = useLocation();
    const navigate = useNavigate();
    let tagCode = pathname.substring(pathname.lastIndexOf('/') + 1);

    useEffect(() => {
        navigate(`/tag/${tagCode}?tabId=${generateUniqueId()}`);
    }, []);

    function generateUniqueId(): string {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let uniqueId = '';
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            uniqueId += characters[randomIndex];
        }
        return uniqueId;
    }

    return (
        <div>
        </div>
    );
};

export default TabIdGeneratorMiddlewarePage;
