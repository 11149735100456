import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'en',
        supportedLngs: ['en', 'fr'],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    "home_page_text": "This is the home page.",
                    "no_tag_page_title": "The tag either does not exist or there isnt any pet associated with it",
                    "cat": "Cat",
                    "dog": "Dog",
                    "years_old": "Years Old",
                    "months_old": "Months Old",
                    "days_old": "Days Old",
                    "description_bio": "Description (Bio)",
                    "male": "Male",
                    "female": "Female",
                    "contacts": "Contacts",
                    "user_no_email_message": "The user does not have any email address",
                    "user_no_phone_message": "The user does not have any phone number",
                    "main_page_code_explication": "The code is on the back of the tag. You can enter the corresponding numbers and letters in the bar above.",
                    "ready_to_start": "Ready to start?",
                    "need_help": "Need Help?",
                    "search": "Search",
                    "have_found_a_pet": "Have you found a pet?",
                    "enter_tag_code": "Enter tag code",
                    "enter_microchip_code": "Enter microchip code",
                    "download_app": "Download App",
                    "go_back": "Go Back",
                    "download_page_text": "If you are not automatically redirected, click on the download button.",
                    "user_has_been_alerted": "The user has been alerted",
                    "alert_owner": "Alert the Owner",
                    "lost": "Lost",
                    "no_pet_page_title": "This id does not have any pet associated with it.",
                    "no_microchip_page_title": "This microchip does not have any pet associated with it.",
                    "additional_info": "Additional information",
                    "microchip": "Microchip",
                    "breed": "Breed",
                    "species": "Species",
                    "age": "Age",
                    "sex": "Sex",
                    "microchipRegexAlert": "Invalid input. Please enter a 15-character number.",
                    "microchip_Link_to_download_app": "If this number matches your microchip, download the application.",
                    "all_right_reserved": "All Rights Reserved.",
                    "location_not_shared": "The location information has not been shared. Please ensure the location settings are enabled.",
                    "1min_snackbar_message": "The last alert was sent less than 1 min ago. Please wait to send another one.",
                    "quebec_company": "Canadian Company",
                    "enter_pet_id_code": "Enter the pet's identification number",
                    "consult_medical_history": "Consult medical history",
                    "user_hasnt_accepted_sharing_info": "The user hasn't accepted to share his contact information",
                    "iso_number": "Microchip ISO Number: 991000000012345",
                    "tag_number": "Tag Number: ABC123",
                    "rage_number": "Rage Number: ABC123",
                    "citY_license": "City License: ABC123",
                    "lost_pet_list": "List of lost pets",
                    "found_this_pet": "I found this pet",
                    "back_to_vetygo": "Back to www.vetygo.ca",
                    "back_to_marengoapp": "Back to www.marengoapp.ca",
                    "vet_microchip_title": "Oops, this microchip does not seem to have been linked. However, it appears to belong to this establishment. For more information, please contact them:",
                    "faq": {
                        "title": "FAQ: Why Haven’t I Received the Notification with the Current Location?",
                        "location_permissions_title": "Have you granted location permissions to the browser?",
                        "location_permissions_description": "Ensure that your browser has permission to access your location. Check this in your browser’s settings under \"Privacy\" or \"Permissions.\"",
                        "phone_notifications_title": "Is your phone blocking notifications?",
                        "phone_notifications_description": "Ensure that notifications are enabled on your phone and the Marengo app.",
                        "scan_timing_title": "Has it been less than 1 minute since the last scan?",
                        "scan_timing_description": "To prevent spam, alert notifications are only sent once per minute.",
                        "tag_scan_title": "Did you scan the tag or refresh the page?",
                        "tag_scan_description": "For security reasons, notifications are sent only when the tag is scanned again."
                    },
                    "for_more_info": "For more information: ",
                    "english": "English",
                    "french": "French",
                    "lostOn": "Lost on  ",
                    "microchip_Link_to_aaha": "This microchip is not in our registry. You can try searching at aaha, which is the biggest microchip registry in Canada.",
                }
            },
            fr: {
                translation: {
                    "home_page_text": "Voici la page principale",
                    "no_tag_page_title": "La médaille n'existe pas ou aucun animal n'y est associé.",
                    "cat": "Chat",
                    "dog": "Chien",
                    "years_old": "Ans",
                    "months_old": "Mois",
                    "days_old": "Jours",
                    "description_bio": "Description (Bio)",
                    "male": "Mâle",
                    "female": "Femelle",
                    "contacts": "Contacts",
                    "user_no_email_message": "L'utilisateur n'a pas d'adresse électronique",
                    "user_no_phone_message": "L'utilisateur n'a pas de numéro de téléphone",
                    "main_page_code_explication": "Le code se trouve au dos de la médaille. Vous pouvez saisir les numéros et lettres correspondants dans la barre ci-dessus.",
                    "ready_to_start": "Prêt à commencer l'exprérience?",
                    "need_help": "Besoin d'aide?",
                    "search": "Rechercher",
                    "have_found_a_pet": "Vous avez trouver un animal?",
                    "enter_tag_code": "Saisir le code de la médaille",
                    "enter_microchip_code": "Saisir le code de la micropuce",
                    "download_app": "Télécharger l'application",
                    "go_back": "Retour",
                    "download_page_text": "Si vous n'êtes pas rediriger automatiquement, clicker sur le boutton télécharger.",
                    "user_has_been_alerted": "L'utilisateur a été alerté",
                    "alert_owner": "Alerter le propriétaire",
                    "lost": "Perdu",
                    "no_pet_page_title": "Aucun animal de compagnie n'est associé à cet identifiant.",
                    "no_microchip_page_title": "Aucun animal de compagnie n'est associé à cette micropuce.",
                    "additional_info": "Informations supplémentaires",
                    "microchip": "Micropuce",
                    "breed": "Race",
                    "species": "Espèce",
                    "age": "Âge",
                    "sex": "Sexe",
                    "microchipRegexAlert": "Entrée invalide. Veuillez saisir un nombre de 15 caractères.",
                    "microchip_Link_to_download_app": "Si ce numéro correspond à votre micropuce, télécharger l'application.",
                    "all_right_reserved": "Tous droits réservés.",
                    "location_not_shared": "Les informations de localisation n'ont pas été partagées. Veuillez vérifier que les paramètres de localisation sont activés.",
                    "1min_snackbar_message": "La dernière alerte a été envoyée il y a moins d'une minute. Veuillez attendre avant d'en envoyer une autre.",
                    "quebec_company": "Entreprise du Québec",
                    "enter_pet_id_code": "Saisissez le numéro d’identification de l'animal",
                    "consult_medical_history": "Consulter l'historique médical",
                    "user_hasnt_accepted_sharing_info": "L'utilisateur n'a pas accepté de partager ses informations",
                    "iso_number": "Numéro de micropuce ISO: 991000000012345",
                    "tag_number": "Numéro de médaille: ABC123",
                    "rage_number": "Numéro de rage: ABC123",
                    "citY_license": "Numéro de licence de la ville: ABC123",
                    "lost_pet_list": "Liste des animaux perdus",
                    "found_this_pet": "J'ai trouvé cet animal",
                    "back_to_vetygo": "Retour vers le site www.vetygo.ca",
                    "back_to_marengoapp": "Retour vers le site www.marengoapp.ca",
                    "vet_microchip_title": "Oups, cette micropuce ne semble pas avoir été liée. Cependant, elle semble appartenir à cet établissement. Pour plus d'informations, veuillez les contacter :",
                    "faq": {
                        "title": "FAQ : Pourquoi n’ai-je pas reçu la notification avec la localisation actuelle?",
                        "location_permissions_title": "Avez-vous autorisé la localisation dans le navigateur?",
                        "location_permissions_description": "Assurez-vous que votre navigateur a l'autorisation d'accéder à votre localisation. Vérifiez cela dans les paramètres de votre navigateur.",
                        "phone_notifications_title": "Votre téléphone bloque-t-il les notifications?",
                        "phone_notifications_description": "Assurez-vous que les notifications sont activées sur votre téléphone et dans l'application Marengo.",
                        "scan_timing_title": "S'est-il écoulé moins d'une minute depuis le dernier scan?",
                        "scan_timing_description": "Pour éviter le spam, les notifications d'alerte sont envoyées une fois par minute seulement.",
                        "tag_scan_title": "Avez-vous scanné la médaille ou actualisé la page?",
                        "tag_scan_description": "Pour des raisons de sécurité, les notifications sont envoyées uniquement lorsque la médaille  est scannée à nouveau."
                    },
                    "for_more_info": "Pour plus d'informations: ",
                    "english": "Anglais",
                    "french": "Français",
                    "lostOn": "Perdu le ",
                    "microchip_Link_to_aaha": "Cette micropuce ne figure pas dans notre registre. Vous pouvez essayer de faire une recherche sur aaha, qui est le plus grand registre de micropuces au Canada",
                }
            }
        }
    });

export default i18n;